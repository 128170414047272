<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="描述:">
                    <el-input v-model="queryParams.desc" placeholder="请输入描述" clearable>
                    </el-input>
                </el-form-item>
                <el-form-item label="key:">
                    <el-input v-model="queryParams.key" placeholder="请输入key" clearable>
                    </el-input>
                </el-form-item>
                <div style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </div>
            </el-form>
            <!-- 表格内容 -->
            <el-table v-loading="loading" element-loading-text="数据加载中" stripe border :data="pageInfo.list" style="margin-top: 30px;">
                <el-table-column label="id" prop="id" align="center" width="160"></el-table-column>

                <el-table-column label="key" prop="key" align="center"></el-table-column>

                <el-table-column label="插件表描述" prop="desc" align="center"></el-table-column>
                <el-table-column label="状态" prop="state" align="center" width="200">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.state === 1">下线</el-tag>
                        <el-tag type="primary" v-if="scope.row.state === 2">上线</el-tag>
                    </template>
                </el-table-column>
                <!-- <el-table-column label="操作" align="center" width="170" fixed="right">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.state === 2" type="text" class="red" @click="state(scope.row, 1)">下线
                        </el-button>
                        <el-button v-if="scope.row.state === 1" type="text" class="green" @click="state(scope.row, 2)">
                            上线</el-button>
                    </template>
                </el-table-column> -->
            </el-table>
        </div>

    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import { request } from '@/common/request';
import { deal } from '@/common/main';
export default {
    name: 'StoreInfo',
    components: {
        Breadcrumb,
        Pagination
    },
    data() {
        return {
            header: {},
            confirmVisible: false,
            confirmContent: '',
            pickerOptions: {
                disabledDate(time) {
                    // return time.getTime() < Date.now() - 8.64e7;
                }
            },
            menuList: ['功能', '插件表管理'],
            loading: false,
            queryParams: {
                name: '',
                page: 1,
                pagesize: 10,
                platform: 'admin'
            },
            pageInfo: {},
            addVisible: false,
            title: '添加插件表',
            rules: {
                r: [{
                    required: true,
                    message: '排序不能为空',
                    trigger: 'blur'
                }],
                name: [{
                    required: true,
                    message: '名称不能为空',
                    trigger: 'blur'
                }],
                desc: [{
                    required: true,
                    message: '品牌描述不能为空',
                    trigger: 'blur'
                }],
            },
            form: {
                state: 1,
                name: '',
                time: null
            }
        }
    },
    created() {
        this.header.token = localStorage.getItem('token');
        this.header.platform = 'admin';
        this.loadData()
    },
    methods: {
        //插件表列表
        loadData() {
            this.loading = true;
            request.get('/plug/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    this.pageInfo.list = ret.data.list;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        changeQuery() {
            this.queryParams.page = 1
            this.loadData()
        },

    }
}
</script>

<style scoped>
.el-table th > .cell {
    text-align: center;
}

.el-table .cell {
    text-align: center;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin-left: 200px;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader {
    padding-bottom: 13px;
}

.el-icon-plus avatar-uploader-icon {
    margin-right: 50px;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 70px;
    height: 70px;
    line-height: 110px;
    text-align: center;
}

.avatar-uploader .avatar-uploader-icon {
    width: 100px;
    height: 100px;
    line-height: 100px;
}

.avatar-uploader .avatar {
    width: 100px;
    height: 100px;
}

.avatar {
    width: 100px;
    height: 100px;
    display: block;
    font-size: 28px;
    line-height: 100px;
}
</style>